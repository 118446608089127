
import { computed, defineComponent, onMounted, ref } from "vue";

import PageGreeting from "@/components/PageGreeting.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import ProgressGauge from "@/components/ProgressGauge.vue";
import StudentRank from "@/components/StudentRank.vue";
import CourseStickers from "@/components/CourseStickers.vue";

import { AppActionTypes, useStore } from "@/store";
import { StudentCourseProgressModel, StudentProgressModel } from "@/models";
import { mapStudentCourseOptions } from "@/helpers";

export default defineComponent({
    components: {
        "page-greeting": PageGreeting,
        "progress-bar": ProgressBar,
        "progress-gauge": ProgressGauge,
        "student-rank": StudentRank,
        "course-stickers": CourseStickers
    },
    setup() {
        const { getters, dispatch } = useStore();
        const studentProgress = ref<StudentProgressModel | null>(null);
        const selectedCourseId = ref<number | null>(null);

        /**
         * Aggregates a number property on the course progress.
         * Ex aggregateCourseProgress(scp => scp.Points) will return the total number of points across all courses.
         */
        const aggregateCourseProgress = (
            selector: (scp: StudentCourseProgressModel) => number | undefined
        ): number | undefined => {
            if (!studentProgress.value || !studentProgress.value.courses) {
                return undefined;
            }

            /**
             * Provide neutral initial value to `.reduce()`
             * Prevents TypeError: Reduce of empty array with no initial value
             * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Reduce_of_empty_array_with_no_initial_value#valid_cases
             */
            return studentProgress.value.courses
                .map(selector)
                .map((x) => x || 0)
                .reduce((current, next) => current + next, 0);
        };

        /**
         * A computed property for aggregated course progress information
         * Used to display results for when the user selects "All" in the dropdown
         */
        const aggregatedCourse = computed(() => {
            if (!studentProgress.value || !studentProgress.value.courses) {
                return null;
            }

            const result: StudentCourseProgressModel = {
                name: "All",
                courseId: 0,

                points: aggregateCourseProgress((scp) => scp.points),
                totalPoints: aggregateCourseProgress((scp) => scp.totalPoints),
                stickersCollected: aggregateCourseProgress(
                    (scp) => scp.stickersCollected
                ),
                totalStickers: aggregateCourseProgress(
                    (scp) => scp.totalStickers
                ),
                quizzesPassed: aggregateCourseProgress(
                    (scp) => scp.quizzesPassed
                ),
                totalQuizzes: aggregateCourseProgress(
                    (scp) => scp.totalQuizzes
                ),
                videosWatched: aggregateCourseProgress(
                    (scp) => scp.videosWatched
                ),
                totalVideos: aggregateCourseProgress((scp) => scp.totalVideos),
                booksRead: aggregateCourseProgress((scp) => scp.booksRead),
                totalBooks: aggregateCourseProgress((scp) => scp.totalBooks)
            };

            return result;
        });

        /**
         * Gets a course model by the one selected by the user in the template (see selectedCourseId)
         */
        const selectedCourse = computed(() => {
            if (
                !studentProgress.value ||
                !studentProgress.value.courses ||
                !aggregatedCourse.value
            ) {
                return null;
            }

            const courses = [
                aggregatedCourse.value,
                ...studentProgress.value.courses
            ];

            return courses.find((c) => c.courseId === selectedCourseId.value);
        });

        /**
         * Creates a select list of courses to use for the course selection control in the template
         */
        const courseOptions = computed(() => [
            // Add an "all" option to display the aggregated course
            {
                text: "All",
                value: (aggregatedCourse.value?.courseId || 0).toString()
            },
            ...mapStudentCourseOptions(studentProgress.value?.courses)
        ]);

        /**
         * Gets student progress for the currently logged in student.
         * This is called onMounted to load data for the page.
         */
        async function getStudentProgress(): Promise<void> {
            // Get the student progress from the action
            studentProgress.value = await dispatch(
                AppActionTypes.getStudentProgress,
                {}
            );

            // Default select the "All" aggregated course.
            if (aggregatedCourse.value) {
                selectedCourseId.value = aggregatedCourse.value.courseId || 0;
            }
        }

        // On mounted, load the student progress to display on the page
        onMounted(() => getStudentProgress());

        return {
            loading: computed(() => getters.loading),
            studentProgress,
            selectedCourseId,
            selectedCourse,
            courseOptions,
            rank: computed(() => studentProgress.value?.rank || {})
        };
    }
});
