
import { loadUserDependentData } from "@/helpers";
import { AppActionTypes, useStore } from "@/store";
import { defineComponent, computed } from "vue";

export default defineComponent({
    props: {
        courseId: {
            type: Number,
            required: false,
            default: 0
        }
    },
    setup(props) {
        const store = useStore();
        const { state } = store;

        const courseStickers = computed(() => {
            if (!state.stickers) {
                return [];
            }

            if (!props.courseId) {
                return state.stickers;
            }

            return state.stickers.filter(
                (sticker) => sticker.courseId === props.courseId
            );
        });

        loadUserDependentData(store, async () => {
            await store.dispatch(AppActionTypes.getStickers);
        });

        return {
            courseStickers
        };
    }
});
