
import { defineComponent } from "vue";
import { CircularGauge } from "@progress/kendo-vue-gauges";

export default defineComponent({
    components: {
        "circular-gauge": CircularGauge
    },
    props: {
        value: {
            type: Number,
            required: true
        },
        primary: {
            type: Boolean,
            default: true
        },
        center: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: false,
            default: null
        }
    },
    setup(props) {
        // const progressGaugeValue = computed(() =>
        //     Math.floor((props.value / props.total) * 100)
        // );
        // return {
        //     progressGaugeValue
        // };

        return {
            classNameVariation: props.primary
                ? "text-primary"
                : "text-secondary",
            gaugeColor: props.primary ? "#2698bd" : "#5c9b37" // TODO: Make dynamic for themes
        };
    }
});
