import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courseStickers, (sticker) => {
      return (_openBlock(), _createElementBlock("div", {
        key: sticker.lessonId,
        class: "col-xl-1 col-lg-2 col-md-4 col-6 p-2"
      }, [
        _createElementVNode("img", {
          src: sticker.imageUrl ?? '',
          class: _normalizeClass(["img-fluid", { grayscale: !sticker.isEarned }]),
          alt: `${sticker.lessonName} Sticker`
        }, null, 10, _hoisted_2)
      ]))
    }), 128))
  ]))
}