import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "gauge-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "font-weight-bold font-raleway"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_circular_gauge = _resolveComponent("circular-gauge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_circular_gauge, {
      class: "sticker-sizing gauge-primary",
      value: _ctx.value,
      "center-render": "centerRender",
      scale: {
                rangeLineCap: 'square',
                rangeSize: 12
            },
      color: _ctx.gaugeColor
    }, {
      centerRender: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass(["gauge-center", _ctx.classNameVariation])
        }, _toDisplayString(_ctx.center), 3)
      ]),
      _: 1
    }, 8, ["value", "color"]),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true)
  ]))
}